<template>
  <div v-if="IsImpacted === true">
    <router-link to="/status">
      <div class="alert">
        <h1>aya may be experiencing service issues.</h1>
        <a>Click this banner for more details.</a>
      </div>
    </router-link>
  </div>
  <router-view :status="this.$root.status"/>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      status: {}
    }
  },

  computed: {
    IsImpacted() {
      // There is probably a better way of doing this, but i'm lazy.

      let impacted = false;

      Object.entries(this.status).forEach((entry) => {
        // eslint-disable-next-line no-unused-vars
        const [key, value] = entry;

        if(value === false) {
          impacted = true;
        }
      })

      return impacted;
    }

  },

  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'aya.so';
    }
  },

  methods: {
    async getStatus() {
      try {
        await axios.get('https://api.aya.so/api/status')
        .then(response => {
          let data = response.data;
          this.status = data;
        })
      } catch(err) {
        this.status = '429';
      }
    }
  },

  mounted() {
    var data = '<!--彡★彡☆ dzn by spce.moe 彡★彡☆彡-->'
    document.body.insertAdjacentHTML('beforeend', data);
    this.getStatus();
  }
}
</script>

<style>
.alert {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgb(202, 99, 62);
  padding: 5px;
}

.alert h1 {
  letter-spacing: 1px;
  font-weight: bold;
}

body {
  background-color: #1d1d1f;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffefe0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
